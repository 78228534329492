import React, { useEffect, useState } from "react"
import { graphql, navigate } from "gatsby"
import { isBefore } from "date-fns"
import Select from "react-select"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ResourcesCard from "../../components/resourcesCard"
import BeFeaturedBanner from "../../components/beFeaturedBanner"

import { motion } from "framer-motion"

function ResourcesPage({ data, location }) {
  const webinars = (data ?? {}).webinars
  const podcasts = (data ?? {}).podcasts
  const tipTalks = (data ?? {}).tipTalks
  const pressReleases = (data ?? {}).pressReleases

  // console.log(webinars)

  const dropdown = [
    {
      label: "View All Resources",
      value: ["summit2021", "podcasts", "webinars", "tipTalks", "pressReleases"],
    },
    {
      label: "Summit 2021: Transforming Mobility",
      value: ["summit2021"]
    },
    {
      label: "Podcasts",
      value: ["podcasts"],
    },
    {
      label: "Webinars",
      value: ["webinars"],
    },
    {
      label: "TIP Talks",
      value: ["tipTalks"],
    },
    {
      label: "Press Releases",
      value: ["pressReleases"],
    },
  ]

  const [filter, setFilter] = useState(
    new URLSearchParams(location.search).get("q") ?? dropdown[0].value
  )

  const [resources, setResources] = useState([])

  useEffect(() => {
    const w = filter.includes("webinars") || filter.includes("summit2021")
      ? webinars.edges.map((webinar) => {
        
        // if summit 2021 selected, show only webinars with appropriate event name, if webinars selected, show all webinars 
        if ((filter.includes("summit2021") && webinar.node.event?.name === 'Transforming Mobility: The Auto Innovators Summit') || filter.includes("webinars")) {
          return (
            <ResourcesCard
              key={webinar.node._id}
              id={webinar.node._id}
              name={
                webinar.node?.events?.name
                  ? `${webinar.node?.events?.name} — ${webinar.node.name}`
                  : `${webinar.node.name}`
              }
              date={webinar.node.date}
              type={
                webinar.node?.event?.name.startsWith("Transforming Mobility")
                  ? "TRANSFORMING MOBILITY"
                  : "WEBINAR"
              }
              onClick={() =>
                navigate(`webinars?id=${webinar.node._id}`, { state: webinar })
              }
            />
          )
        }
      })
      : []

    const p = filter.includes("podcasts")
      ? podcasts.edges.map(podcast => (
          <ResourcesCard
            key={podcast.node._id}
            id={podcast.node._id}
            name={`Season ${podcast.node.season}, Ep. ${podcast.node.episode} — ${podcast.node.name}`}
            date={podcast.node.date}
            type="PODCAST"
            onClick={() =>
              navigate(`podcasts?id=${podcast.node._id}`, { state: podcast })
            }
          />
        ))
      : []

    const t = filter.includes("tipTalks")
      ? tipTalks.edges.map(tipTalk => (
          <ResourcesCard
            key={tipTalk.node._id}
            id={tipTalk.node._id}
            name={tipTalk.node.name}
            date={tipTalk.node.date}
            thumbnail={tipTalk?.node?.thumbnail?.asset?.url}
            type="TIP TALK"
            onClick={() =>
              navigate(`tiptalks?id=${tipTalk.node._id}`, { state: tipTalk })
            }
          />
        ))
      : []

    const r = filter.includes("pressReleases")
      ? pressReleases.edges.map(pressRelease => (
          <a
            href={pressRelease.node.file?.asset.url ?? "#"}
            key={pressRelease.node._id}
          >
            <ResourcesCard
              id={pressRelease.node._id}
              name={pressRelease.node.name}
              date={pressRelease.node.date}
              type="PRESS RELEASE"
            />
          </a>
        ))
      : []

    const combined = [...w, ...p, ...t, ...r]

    const sorted = combined.sort((a, b) => {
      const dateA = a.props?.date ?? a.props?.children?.props.date
      const dateB = b.props?.date ?? b.props?.children?.props.date

      const val = isBefore(new Date(dateA), new Date(dateB)) ? 1 : -1
      return val
    })

    setResources(sorted)
  }, [filter])

  return (
    <Layout title="resources">
      <Seo title="Resources" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <section className="flex items-center justify-center py-24 w-full bg-resources-page-header-colorized bg-cover bg-center bg-no-repeat lg:py-40">
          <h1 className="text-white text-4xl italic font-bold lg:text-6xl xl:text-7xl">
            RESOURCES
          </h1>
        </section>

        <section className="container flex flex-col items-center justify-center mx-auto py-8 w-full space-y-4 lg:flex-row lg:py-16 lg:space-y-0">
          <Select
            options={dropdown}
            onChange={({ value }) => setFilter(value)}
            className="w-80 md:w-1/4"
          ></Select>
        </section>

        <section className="flex flex-col items-center justify-center py-16 w-full bg-gray-100 lg:py-24">
          <div className="mx-auto text-center space-y-4">
            <div className="grid gap-4 grid-cols-1 2xl:grid-cols-4 text-left lg:grid-cols-2 xl:grid-cols-3">
              {resources}
            </div>
          </div>
          {/* <div className="mx-auto mt-16">
					<button
						className="
						border-2
						font-bold
						text-black
						border-black
						py-2
						px-4
						rounded-full
						min-w-300
						transition
						focus:outline-none
						hover:bg-black
						hover:text-white"
					>
						LOAD MORE
					</button>
				</div> */}
        </section>
        <BeFeaturedBanner />
      </motion.div>
    </Layout>
  )
}

export default ResourcesPage

export const query = graphql`
  {
    tipTalks: allSanityTiptalk {
      edges {
        node {
          _id
          date
          name
          thumbnail {
            asset {
              url
            }
          }
        }
      }
    }
    webinars: allSanityWebinar {
      edges {
        node {
          _id
          date
          name
          event {
            name
          }
        }
      }
    }
    podcasts: allSanityPodcast {
      edges {
        node {
          _id
          date
          name
          episode
          season
        }
      }
    }
    pressReleases: allSanityPressrelease {
      edges {
        node {
          _id
          date
          name
          file {
            asset {
              url
            }
          }
        }
      }
    }
  }
`
