import React from "react"
import { format } from "date-fns"

import overtakeThumbnail from "../images/featured/overtake_thumbnail.png"
import futureDrivenThumbnail from "../images/featured/future_driven_thumbnail.png"
import pressReleaseThumbnail from "../images/featured/press_release_thumbnail.jpg"
import transformingMobilityThumbnail from "../images/featured/transforming_mobility_thumbnail.png"

const thumbnails = {
  PODCAST: overtakeThumbnail,
  WEBINAR: futureDrivenThumbnail,
  "PRESS RELEASE": pressReleaseThumbnail,
  "TRANSFORMING MOBILITY": transformingMobilityThumbnail,
}

function ResourcesCard({ name, type, date, thumbnail, onClick, id }) {
  return (
    <div
      className="max-h-96
				bg-gray-800 mx-auto
				text-left lg:max-w-auto relative w-full max-w-xs text-white cursor-pointer lg:w-80"
      onClick={onClick}
    >
      <img
        className="relative mx-auto w-full h-40 object-cover object-center"
        src={thumbnails[type] ?? thumbnail}
        alt="Thumbnail"
      />
      <div className="pb-16 pt-8 px-4 space-y-2 lg:px-6">
        <p className="text-xs italic font-bold lg:text-sm">
          {format(new Date(date), "MMMM dd, yyyy") ?? "..."}
        </p>
        <h2
          className="max-h-20
					line-clamp-2 text-sm font-bold md:text-base lg:text-lg"
        >
          {name ?? `...`}
        </h2>
        <p className="absolute bottom-0 left-0 pb-6 px-4 text-gold text-xs font-bold lg:pb-8 lg:px-6">
          {type ?? `...`}
        </p>
        {type === "PRESS RELEASE" && (
          <div className="absolute bottom-0 right-0 pb-6 px-6 lg:pb-8 lg:px-6">
            <svg width="14" height="16" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 15C0 14.4477 0.44772 14 1 14H13C13.5523 14 14 14.4477 14 15C14 15.5523 13.5523 16 13 16H1C0.44772 16 0 15.5523 0 15ZM3.29289 7.29289C3.68342 6.90237 4.31658 6.90237 4.70711 7.29289L6 8.5858V1C6 0.44772 6.44771 0 7 0C7.5523 0 8 0.44771 8 1V8.5858L9.2929 7.29289C9.6834 6.90237 10.3166 6.90237 10.7071 7.29289C11.0976 7.68342 11.0976 8.3166 10.7071 8.7071L7.7071 11.7071C7.5196 11.8946 7.2652 12 7 12C6.73478 12 6.48043 11.8946 6.29289 11.7071L3.29289 8.7071C2.90237 8.3166 2.90237 7.68342 3.29289 7.29289Z"
                fill="#979797"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  )
}

export default ResourcesCard
